import "./App.css";
import React, { useRef, useState,useEffect } from 'react'
import { Canvas, useLoader,useFrame } from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'

const apiKey = process.env.REACT_APP_API_KEY;
const cmsURL = "https://rhcms.origin.berlin"; 

const headers = new Headers({
  'Authorization': `Bearer ${apiKey}`
})

function Box(props) {
  // This reference gives us direct access to the THREE.Mesh object
  const ref = useRef()
  // Hold state for hovered and clicked events
  const [hovered, hover] = useState(false)
  const [clicked, click] = useState(false)
  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame((state, delta) => (ref.current.rotation.x += delta))
  // Return the view, these are regular Threejs elements expressed in JSX
  const colorMap = useLoader(TextureLoader, props.img)
  return (
    <mesh
      {...props}
      ref={ref}
      scale={clicked ? 1.5 : 1}
      onClick={(event) => {click(!clicked);alert(props.title)}}
      onPointerOver={(event) => hover(true)}
      onPointerOut={(event) => hover(false)}>
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial  map={colorMap} />
    </mesh>
  )
}

function App() {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    fetch(cmsURL + '/api/projects?populate=image',{headers})
         .then((response) => response.json())
         .then((data) => {
            const sanitized = data.data.map((post) => {
              post.content = {__html:post.attributes.content}
              return post;
            })
            setPosts(sanitized);
         })
         .catch((err) => {
            console.log(err.message);
         });
  }, []);

  return (
    <Canvas>
    <ambientLight intensity={Math.PI / 2} />
    <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} decay={0} intensity={Math.PI} />
    <pointLight position={[-10, -10, -10]} decay={0} intensity={Math.PI} />
    {posts.map((post,i) => {
      return (
        <Box key={i} title={post.attributes.title} img={cmsURL + post.attributes.image.data.attributes.url} position={[i * 2 - posts.length / 2, 0, 0]} />
      )
    })}
  </Canvas>
  //   <div className="posts-container">
  //       {posts.map((post) => {
  //        return (
  //           <div className="post-card" key={post.id}>
  //              <h2 className="post-title">{post.attributes.title}</h2>
  //              <img width="200" src={cmsURL + post.attributes.image.data.attributes.url} />
  //              <div dangerouslySetInnerHTML={post.content}></div> 
  //           </div>
  //        );
  //     })}
  //  </div>
  );
}

export default App;
